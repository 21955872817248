<template>
  <b-card>
    <div class="text-underline title-h2 mb-3 mt-2">{{ $t('admin.duplicate.title') }}</div>
    <div class="simple-label-avenir mb-4">{{ $t('admin.duplicate.description') }}</div>

    <d-body-nav-bar
      :tabs="tabs"
      @tab-changed="selectedTab = $event.id"
    />
    <template v-if="isLoaded">
      <category-index v-if="selectedTab === 'categories'"/>
      <product-index v-if="selectedTab === 'products'"/>
      <calendar-index v-if="selectedTab === 'calendar'"/>
      <block-price-category-index v-if="selectedTab === 'block-price-categories'"/>
      <block-price-index v-if="selectedTab === 'block-prices'"/>
    </template>
  </b-card>
</template>

<script>
import {getClubs} from "@api/services/club/club.api";

export default {
  components: {
    CategoryIndex: ()=> import('./shop/Index'),
    CalendarIndex: ()=> import('./calendar/Index'),
    BlockPriceCategoryIndex: ()=> import('./block-price-category/Index'),
    BlockPriceIndex: ()=> import('./block-price/Index'),
    ProductIndex: ()=> import('./shop/product/Index'),
  },
  data: () => ({
    duplicationModel: false,
    isLoaded: false,
    search: '',
    selectedTab: 'categories',
    clubs: [],
    tabs: [
      {name: 'admin.duplicate.menus.categories-shop', active: true, id: 'categories'},
      {name: 'admin.duplicate.menus.calendars', active: false, id: 'calendar'},
      {name: 'admin.duplicate.menus.block-price-categories', active: false, id: 'block-price-categories'},
      {name: 'admin.duplicate.menus.block-prices', active: false, id: 'block-prices'},
    ]
  }),
  computed: {
    clubsIsLoaded() {
      return this.$store.getters['club/getIsLoaded'];
    },
    isCategoryListActivated() {
      return this.$store.getters['duplicate/getActivateCategoryList'];
    }
  },
  methods: {
    scrollToCategoryList() {
      this.$store.commit('duplicate/setActivateCategoryList', !this.isCategoryListActivated);
        setTimeout(() => {
          document.getElementById('categoryListDuplicateId').scrollIntoView({behavior: 'smooth', block: 'start'});
        }, 300)
      ;
    },
    onSelect($event) {
      console.log('selected', $event)
    },
    loadClubs(filter = '') {
      this.clubs = [];
      this.isLoaded = false;

      getClubs(1, 500, filter)
        .then((response) => {

          for (const club of response.data['hydra:member']) {
            this.clubs.push({
              label: club.name,
              id: club.id,
              item: club,
              iri: club['@id'],
            });
          }
          this.isLoaded = true;
          this.$store.commit('club/setClubList', this.clubs);
        })
      ;
    },
  },
  mounted() {
    this.loadClubs();
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/_b-card.scss";
</style>
