<template>
  <div>
    <div class="container-fluid">
      <container/>
    </div>
  </div>
</template>
<script>
  import Container from "@views/duplicate/Container";
  export default {
    components: {Container}
  }
</script>
<style lang="scss" scoped>
</style>
